import type { IDataObjectConfig } from 'o365-modules';
import type { DistinctHandler } from './DistinctHandler.ts';
import { Url, dateUtils as utilsDate } from 'o365-utils';
import { checkIfColumn } from './helpers.values.ts';
import { operators } from './constants.ts';
import { getCombinedExpressionIndex, getCombinedExpressionIdByIndex,getCombinedExpressionByID } from './helpers.dateExpressions.ts';

interface FilterItem {
    column: string;
    alias: string;
    operator: string,
    value: any,
    dateExpression: string,
    valueType: string,
    isDate: boolean,
    distinctHandler: DistinctHandler,
    isValueTypeNumber: boolean,

    expressionValue:any,
    useAlias:boolean
}
export default class UrlFilter {
    private _key: string;
    private _operators: Array<any>;
    private _value: string | null = null;
    private useUrl: boolean;
    columns: Array<any>;

    get urlValue() {
        if (this.useUrl) {
            return Url.getParam(this._key);
        } else {
            return this._value;
        }
    }
    set urlValue(pValue) {
        if (this.useUrl) {
            Url.setParam(this._key, pValue);
        } else {
            this._value = pValue;
        }
    }

    get value() {
        const vValue = this.urlValue;
        if (!vValue) return null;
        return this._decodeValue(vValue);
    }

    set value(pValue) {
        if (!pValue) {
            this.urlValue = null;
            return;
        }
        this.urlValue = this._encodeValue(pValue);
    }


    constructor(pKey: string, pColumns: Array<any>, pUseUrl = true) {
        this._key = pKey;
        this.columns = pColumns;
        this._operators = Object.values(operators);
        this.useUrl = pUseUrl;
    }

    update(pItems: Array<FilterItem>) {

        this.value = this._compress(pItems);


    }

    getFilterItems() {
        return this.value == null ? '' : this._decompress(this.value);
    }

    reset() {
        this.value = null;
    }

    private _decodeValue(pValue: string) {
        try{
            return atob(decodeURIComponent(pValue));
        }catch{
            return pValue;
        }
       
    }
    private _encodeValue(pValue: string) {
        return encodeURIComponent(btoa(pValue));
    }



    private _compress(pItems: Array<FilterItem>) {
        const vReturn: any[] = [];
        pItems.forEach(item => {
            vReturn.push(this._compressItem(item));
        })
        if (vReturn.length === 0) return null;
        return JSON.stringify(vReturn);
    }

    private _compressItem(pItem: FilterItem) {

        if(pItem.operator == "search_fnc"){
            return [
                "fnc",
                pItem.currentOptionIndex,
                pItem.value

            ];
        }
        if (['isnull', 'isnotnull', 'isnotblank', 'isblank', 'istrue', 'isfalse'].indexOf(pItem.operator) > -1) {
            // return [this._getColumnIndex(pItem.alias),
            return [pItem.alias,
            this._getOperatorIndex(pItem.operator)
            ];
        } else if(pItem.useAlias && pItem.expressionValue){
            return [pItem.alias,
                this._getOperatorIndex(pItem.operator),
                this._compressValue(pItem),
                this._encodeVal(pItem.expressionValue)
            ];

        }else{
            //   return [this._getColumnIndex(pItem.alias),
            return [pItem.alias,
            this._getOperatorIndex(pItem.operator),
            this._compressValue(pItem)
            ];
        }

    }

    private _compressValue(pItem: FilterItem) {
        if(pItem.value && checkIfColumn(pItem.value)){
            return pItem.value;
        }
        if (pItem.isDate) {
            if (pItem.dateExpression) {
                return getCombinedExpressionIndex(pItem.dateExpression);
            }

            if (pItem.value && pItem.value.constructor === Array) {
                return pItem.value.map(x => formatDate(x));
            }
            

            if (pItem.value && typeof pItem.value == "string") {
                return formatDate(new Date(pItem.value));
            }

            if (pItem.value) {
                return formatDate(pItem.value);
            }
        }
        if (pItem.operator == "exists_clause" && pItem.distinctHandler?.existsObject) {
           // return [pItem.distinctHandler.existsObject, pItem.value.map((x: any) => this._encodeVal(x))]
            return pItem.value.map((x: any) => this._encodeVal(x));
        }
        if (pItem.value && pItem.operator.indexOf('inlist') > -1 && pItem.value.constructor === Array && pItem.isValueTypeNumber) {
            return pItem.value;
        }
        if (pItem.value && pItem.valueType === "string" && pItem.value.constructor === Array) {
            return pItem.value.map(x => this._encodeVal(x));
        }

        if(pItem.isValueTypeNumber && pItem.valueType === "string"){
            return pItem.value;
        }

        if (pItem.valueType === "string") {
            return this._encodeVal(pItem.value);
        }
        return pItem.value;
    }

    private _encodeVal(pValue: string) {
        const utf8Encoder = new TextEncoder();
        const encodedBuffer = utf8Encoder.encode(pValue);
        return String.fromCharCode(...encodedBuffer);
    }


    private _decompress(pHash: string) {
        if (!pHash) return null;
        try {
            const vReturn: any[] = [];
            const vHash = JSON.parse(pHash);


            if(typeof vHash[0][0] == 'string'){
                 vHash.forEach((item:any) => {
                    vReturn.push(this._decompressItem(item));
                })
            }else{
              //  vHash[0].forEach((item:any) => {
                   // vReturn.push(this._decompressItem(item));
               // })
               const vGroup = this._decompressGroup(vHash);
               console.log(vGroup);
               return vGroup;
            }
            return vReturn;
        } catch {
            console.warn("Failed To parse URL")
        }

        return null;
    }
    private _decompressGroup(pItems:Array<any>){
        if(pItems && typeof pItems[0] == "string"){
            return this._decompressItem(pItems);
        }
        let vMode = "and";
        if(pItems[0] == 1 || pItems[0] == 0){
            vMode = pItems[0] == 0?"or":"and";
        }
        const vReturn = {
            items:[],
            type:"group",
            mode:vMode
        }
      
        pItems.forEach((x:Array<any>)=>{
            if(!(x == 1 || x == 0)){
                 vReturn.items.push(this._decompressGroup(x));
            }
               
        })

        return vReturn;
    }
    private _getTypeFromValue(pValue:any){
        if(!pValue) return "string";
        if(pValue.constructor == Array) return typeof pValue[0];

        return typeof pValue;
    }

    private _decompressItem(pItem:any) {
        let expressionVal = null;
        let vColumnProps = this._getColumnFromIndex(pItem[0]);

        if(!vColumnProps && pItem[0] == "fnc"){
            return pItem;
            
        }

        if(!vColumnProps && pItem[0].indexOf(".") > -1){
            let vType = this._getTypeFromValue(this._getTypeFromValue(pItem[2]??pItem[1]))
            vColumnProps = {
                name:pItem[0],
                column:pItem[0],
                valueType:vType,
                type:vType,
                maxLength:512
            }
            this.columns.push(vColumnProps);

        }

        const vOperator = this._getOperatorFromIndex(pItem[1]);
        if (['isnull', 'isnotnull', 'isnotblank', 'isblank', 'istrue', 'isfalse'].indexOf(vOperator) > -1) {
            return {
                column: vColumnProps.name,
                 type:"expression",
                operator: vOperator,
                value: null
            }
        }

        let value = this._decompressValue(pItem[2], vColumnProps);
        /*if(vOperator == "inlist" && typeof value == 'string'){
            value = value.split(",").map(x=>x.trim());
        }*/
        if(pItem.length == 4){
            expressionVal = this._decompressValue(pItem[3], vColumnProps);
            return{
                column: vColumnProps.name,
                type:"expression",
                operator: vOperator,
                value: value,
                expressionValue:expressionVal
            }
        }

        if(value && value.dateExpression){
            return {
                column: vColumnProps.name,
                type:"expression",
                operator: vOperator,
                dateExpression:value.id,
                value: value.value
            }
        }

        return{
                column: vColumnProps.name,
                type:"expression",
                operator: vOperator,
                value: value,
                valueType:vColumnProps.valueType
            }
        
    }

    private _decompressValue(pValue: any, pColumnProps: any) {
        if(pValue && checkIfColumn(pValue)){
            return pValue;
        }
        if (["date", "datetime"].indexOf(pColumnProps["type"]) > -1) {
            const vExpressionID = getCombinedExpressionIdByIndex(pValue);
            if (vExpressionID) {
                const vExpr = getCombinedExpressionByID(vExpressionID);

                return Object.assign({id:vExpressionID, dateExpression:true},vExpr);
            }

            if (pValue && pValue.constructor === Array) {
                return pValue.map(x => unFormatDate(x));
            }

            return unFormatDate(pValue);
            //check maybe it's date expression
        }
        if (pValue && pValue.constructor === Array) {
            return pValue.map(x => this._decodeVal(x));
        }


        if (typeof pValue === "string") {
            return this._decodeVal(pValue);
        }

        return pValue;


    }
    private _decodeVal(pValue: string) {
        if (typeof pValue !== "string") return pValue;
        try {
            // const decodedBuffer = Uint8Array.from(atob(pValue), c => c.charCodeAt(0));
            const decodedBuffer = Uint8Array.from(pValue, c => c.charCodeAt(0));
            const utf8Decoder = new TextDecoder();
            return utf8Decoder.decode(decodedBuffer);
        } catch {
            console.warn("Failed to parse url filter: ", pValue);
            return null;
        }
    }



/*

    private _getColumnIndex(pColumnName: string) {
        return this.columns.findIndex(x => x.name === pColumnName);
    }*/



    private _getColumnFromIndex(pColumn:any) {
        //if (this.columns[pIndex])
           // return this.columns[pIndex];
        return this.columns.find(x=>x.name == pColumn)
       // return null;
    }

    private _getOperatorIndex(pOp:string) {

        return this._operators.findIndex(x => x === pOp);
    }



    private _getOperatorFromIndex(pIndex:number) {
        if (this._operators[pIndex])
            return this._operators[pIndex];

        if(typeof pIndex == "string") return pIndex;

        return null;
    }

}

function formatDate(pDate:any) {
    if(!pDate) return null;
    return parseInt(utilsDate.format(pDate, 'yyMMdd'));
}
function unFormatDate(pDate:any) {
    if(!pDate) return null;
    return utilsDate.unFormat(pDate.toString(), 'yyMMdd');
}

const viewDefinitionCache: Record<string, any> = {};
const dataObjectDefinitionsCache: Record<string, Record<string, string>> = {};
/**
 * Generate compressed url filter from a fitler string
 * @param {string} pFilter Filter string to compress
 * @param {string} pAppId App id with the target data object
 * @param {string} pDataObjectId Target data object id
 */
export async function getCompressedUrlFilterString(pFilter: string, pAppId: string, pDataObjectId: string) {
    if (!pFilter) {
        throw new Error('getCompressedUrlFilterString: Filter string must be provided');
    }
    if (!pAppId) {
        throw new Error('getCompressedUrlFilterString: App id must be provided');
    }
    if (!pDataObjectId) {
        throw new Error('getCompressedUrlFilterString: DataObject id must be provided');
    }

    const { default: api } = await import('o365.modules.data.api.ts');

    if (dataObjectDefinitionsCache[pAppId]?.[pDataObjectId] == null) {
        try {
            const response: { dataObjects: IDataObjectConfig[], viewDefinition: any[] } = await api.requestGet(`/api/apps/${pAppId}.0.json`);
            dataObjectDefinitionsCache[pAppId] = {};
            response.dataObjects.forEach(definition => {
                dataObjectDefinitionsCache[pAppId][definition.id] = definition.viewName;
                viewDefinitionCache[definition.viewName] = response.viewDefinition[definition.viewName];
            });
        } catch (ex) {
            throw new Error(`Failed to retreive dataobject definitions for app ${pAppId}`);
        }
    }
    const viewName = dataObjectDefinitionsCache[pAppId][pDataObjectId];
    if (viewName == null) {
        throw new Error(`${pDataObjectId} does not exist on ${pAppId}`);

    }
    if (viewDefinitionCache[viewName] == null) {
        throw new Error(`${viewName} is not present on ${pAppId}`);

    }
    const { filterStringToFilterItems } = await import('o365.modules.filterUtils.ts');

    const columns = viewDefinitionCache[viewName].map((x:any) => ({ name: x.fieldName }));
    const urlCompressor = new UrlFilter(`f_${pDataObjectId}`, columns, false);
    const filter = await filterStringToFilterItems(pFilter);
    const filterItems: any[] = [];
    const recursiveFilterMap = (filter:any) => {
        if (filter.items) {
            filter.items.forEach((item:any) => recursiveFilterMap(item));
        } else if (filter.column) {
            if (filter.alias == null) { filter.alias = filter.column };
            filterItems.push(filter);
        }
    }
    if (filter && filter.items) {
        recursiveFilterMap(filter);
        urlCompressor.update(filterItems);
        return urlCompressor.urlValue ? `f_${pDataObjectId}=${urlCompressor.urlValue}` : '';
    } else {
        throw new Error('Invalid filter string provided');
    }
}
